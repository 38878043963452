import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { trigger, transition, animate, style, state } from '@angular/animations';
import { GoogleService } from '../../services/google.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.css'],
  animations: [
    trigger('slideInOut', [
      state('small', style({ top: '-80px' })),
      state('large', style({ top: '0' })),

      transition('small <=> large', animate('250ms 200ms linear'))
    ])
  ]
})
export class MainNavComponent implements OnInit {
  state: string = 'large';
  lastScroll;
  promotionsDataSource;
  promotions = false;

  links = [
    {
      name: "L'instructeur",
      ref: "/instructeur"
    },
    {
      name: "L'entreprise",
      ref: "/entreprise"
    },
    {
      name: "Formations",
      ref: "/formations"
    },
    {
      name: "Publications",
      ref: "/publications"
    },
    {
      name: "Contactez-nous",
      ref: "/contactez-nous"
    },
  ]


  isHandset: Observable<boolean> = this.breakpointObserver.observe(['(max-width: 920px)'])
    .pipe(
      map(result => result.matches)
    );

  constructor(private breakpointObserver: BreakpointObserver,
    private googleService: GoogleService,
    public router: Router,
    private deviceService: DeviceDetectorService) { }

  ngOnInit() {
    this.googleService.getDocument("Promotions").subscribe((data) => {
      var dict = data.values.slice(1).map(row => row.reduce(function (acc, cur, i) {
        acc[data.values[0][i]] = cur;
        return acc;
      }, {}));

      this.promotionsDataSource = dict;

      for (let item of this.promotionsDataSource) {
        if (item.visibility == 1) {
          this.promotions = true;
          break;
        }
      }
    });

  }

  @HostListener('window:scroll', ['$event']) scrollHandler(event) {


    if (this.deviceService.isDesktop()) {
      if (this.lastScroll < window.pageYOffset) {
        // Scroll down
        this.state = 'small';

      } else {
        // Scroll up
        this.state = 'large';
      }
    } else {
      this.state = 'large';
    }

    this.lastScroll = window.pageYOffset;
  }

}
