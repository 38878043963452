<div class="container">

  <div class="content" fxLayout="column">
    <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign="center center" fxLayoutGap="50px">
      <div fxFlex="33">
        <img class="side-image logo" src="../../../assets/cled_logo_square.svg" alt="Logo C-LED Navigation de plaisance">
      </div>
      <div fxFlex>


        <p>Fondée en 2014, C-LED Navigation de plaisance a pour objectif de mettre à profit son savoir, son
          savoir-faire et son savoir-être (sens marin) en navigation de plaisance au service de ses clients.</p>
        <p>Christian Leduc, président de la compagnie, possède plus de trente-cinq années d’expérience dans la maîtrise
          de la voile obtenue sur planche à voile, dériveur, dériveur lesté et quillard.</p>
        <p>Également détenteur d’un baccalauréat en informatique, il a œuvré dans ce domaine pendant plus de trente
          années. Ses connaissances peuvent donc être mises à contribution à bord de voiliers habitables (réseau, WiFi,
          configuration de PC, etc.).</p>
        <h2>Sa mission</h2>
        <p>Mettre à votre service le savoir, le savoir-faire et le savoir-être (sens marin) de l’entreprise pour tout
          ce qui concerne la pratique de la voile en toute sécurité pour l’équipage et l’embarcation.</p>

        <h2>Son expertise</h2>
        <mat-list>
          <mat-list-item>Formation en voile croisière accrédité par Voile Québec (VQ) — Voile Canada (VC)</mat-list-item>
          <mat-list-item>Formation accréditée VQ-VC pour l’obtention d’un certificat restreint d’opérateur radio
            maritime CRO(M)
            (radio VHF/ASN)</mat-list-item>
          <mat-list-item>Formation accréditée VQ-VC pour l’obtention de la carte de conducteur d’embarcation de
            plaisance CCEP de
            Transport Canada</mat-list-item>
          <mat-list-item>Services-conseils sur l’entretien, l’hivernage et la remise à l’eau des voiliers</mat-list-item>
          <mat-list-item>Services-conseils sur le multimédia à bord des voiliers</mat-list-item>
          <mat-list-item>Présentations et conférences sur des sujets variés entourant la navigation de plaisance ainsi
            que la
            voile
            sur quillard</mat-list-item>
        </mat-list>
      </div>
    </div>
  </div>
</div>