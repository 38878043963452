<div class="container">

  <div class="content" fxLayout="column">
    <h1 class="title">Confidentialité</h1>
    <h3>Politique de confidentialité des renseignements personnels</h3>
    <p>Nous avons créé cette politique de confidentialité afin de démontrer notre engagement ferme à protéger votre vie
      privée et la protection de vos informations.</p>
    <h3>Protection des renseignements personnels</h3>
    <p>C-LED Navigation de plaisance s'engage à protéger la confidentialité des informations personnelles tels noms,
      adresse, adresse de courriel, etc. qui lui sont transmises. Ces renseignements sont conservés pour un usage
      interne et ne sont jamais divulgués ou vendus à des tiers.</p>
    <h3>Comment protégeons-nous votre vie privée</h3>
    <p>Nous utilisons des mesures de sécurité appropriées pour protéger contre la perte, l'abus et l'altération des
      données utilisées par notre système.</p>
    <h3>Adresse de courrier électronique</h3>
    <p>Les adresses de courriel des clients sont conservées pour des fins administratives. Nous ne vendons pas de
      listes d'adresses électroniques. À titre de client, vous pourrez cependant recevoir à l'occasion des avis
      concernant nos prochaines formations et conférences.</p>
    <h3>Politique de confidentialité</h3>
    <p>Les informations qui nous sont envoyées sont conservées de façon confidentielle. Nous vous assurons que cette
      information n'est ni divulguée ni diffusée à quiconque en dehors des employés de C-LED Navigation de plaisance.</p>
    <h3>Liens avec les partenaires</h3>
    <p>Notre site présente des liens vers des sites Internet administrés par des tiers et l'accès à un de ces sites
      vous fait quitter celui de C-LED Navigation de plaisance. De ce fait, tout renseignement personnel saisi dans un
      autre site que le nôtre n'est pas soumis à notre politique de confidentialité des renseignements personnels.
      C-LED Navigation de plaisance ne pourra donc jamais être tenue responsable des abus découlant des renseignements
      transmis à d'autres sites.</p>
  </div>

</div>