<form 
    #contactForm="ngForm" 
    (ngSubmit)="onSubmit(contactForm)"
    class="form-content" 
    fxLayout="column"
    >

    <mat-form-field>
        <input 
            matInput
            required
            placeholder="Votre courriel" 
            ngModel
            name="email"
            #email="ngModel"
            type="email" 
            >
    </mat-form-field>

    <mat-form-field>
        <input
            matInput
            required
            placeholder="Sujet"
            ngModel
            name="subject"
            #subject="ngModel"
            type="text"
            >
    </mat-form-field>

    <mat-form-field>
        <textarea 
            matInput
            required
            placeholder="Votre message"
            ngModel
            name="text"
            #text="ngModel"
            type="text" 
            class="message_box" 
        ></textarea>
    </mat-form-field>

    <re-captcha 
        required 
        ngModel
        name="captcha"
        #captcha="ngModel"
        siteKey="6LdJx_cUAAAAAPjtEQkQpzOvX4lOxeKvP8re2U3_"
    ></re-captcha>



    <div fxLayout="row" fxLayoutAlign="end none">
        <button mat-stroked-button color="accent" type="submit">Envoyer</button>
    </div>

</form>