<div class="container">

  <div class="content" fxLayout="column" fxLayoutGap="5%">
    <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign="center center" fxLayoutGap="50px">

      <div fxLayoutAlign="center center">
        <img class="side-image" src="../../../assets/christian.jpg" alt="Christian a la barre">
      </div>

      <div fxFlex="66" class="intro">
        <div class="en-tete ">
          <h1>Christian Leduc</h1>
          <h2>Instructeur accrédité par Voile Québec – Voile Canada</h2>
        </div>

        <div>
          <p>
            Christian Leduc s’adonne à la pratique de la voile depuis plus de trente-cinq années.
          </p>
          <p>
            Au cours de ces années, il a pratiqué ce sport sur plusieurs plans d’eau au Québec, sur le lac Champlain
            ainsi
            que sur la mer des Caraïbes.
          </p>
          <p>
            En plus d’avoir participé à deux courses d’endurance sur le fleuve St-Laurent entre Repentigny et
            Trois-Rivières,
            il a réalisé sa formation de voile croisière intermédiaire Voile-Québec (VQ) et Voile Canada (VC) sur le
            fleuve
            St-Laurent entre Gaspé et Tadoussac. Il a également réalisé sa formation de voile croisière avancée VQ-VC
            sur
            la
            mer des Caraïbes (Martinique — St-Lucie).
          </p>
          <p>
            Pendant onze années, M. Leduc et sa conjointe ont été propriétaires d’un voilier Bénéteau Océanis Clipper
            413
            sur lequel ils ont parcouru durant la saison estivale le lac Champlain et la rivière Richelieu.
          </p>
          <p>
            Détenteur d’une voile de bronze V en dériveur, d’une voile planche bleue, d’un brevet de croisière
            intermédiaire, ainsi qu’un d’un brevet d’instructeur de voile croisière élémentaire, M. Leduc enseigne
            depuis
            2004 la voile croisière.
          </p>

          <p>De plus, M. Leduc a été élu, en novembre 2018, meilleur enseignant de l’année au Québec dans le cadre des
            prix Mérites de Voile Québec.</p>

        </div>

      </div>

    </div>

    <div fxLayoutAlign="center center">
      <mat-divider></mat-divider>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="50px">

      <div fxFlex="33" fxLayoutAlign="center center" fxHide.lt-md>
        <img class="side-image-small" src="../../../assets/experience.jpg" alt="Christian a la barre">
      </div>

      <div fxFlex>
        <div class="en-tete">
          <h2>1980 à ce jour</h2>
          <h4>+7 000 heures, +5 000 milles marin en voile croisière</h4>
        </div>

        <mat-list>
          <mat-list-item> Quillard de 22 à 42 pieds </mat-list-item>
          <mat-list-item> Lac Champlain, Fleuve St-Laurent et mer des Caraïbes </mat-list-item>
          <mat-list-item> Croisières de 3 à 12 jours </mat-list-item>
        </mat-list>

      </div>
    </div>

    <div fxLayoutAlign="center center">
      <mat-divider></mat-divider>
    </div>

    <div div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="50px">

      <div fxFlex="33" fxLayoutAlign="center center" fxHide.lt-md>
        <img class="side-image-small" src="../../../assets/graduation.jpg" alt="Christian a la barre">
      </div>

      <div fxFlex>

        <div class="en-tete">
          <h2>Voile Québec – Voile Canada</h2>
        </div>
        <mat-list>
          <mat-list-item> Brevet de croisière élémentaire (octobre 2001) </mat-list-item>
          <mat-list-item> Brevet de croisière intermédiaire (décembre 2002) </mat-list-item>
          <mat-list-item> Brevet d’instructeur croisière élémentaire (août 2004) </mat-list-item>
          <mat-list-item> Brevet de navigation côtière (février 2004) </mat-list-item>
          <mat-list-item> Autres brevets en dériveur et planche à voile </mat-list-item>
        </mat-list>
        <div class="spacing en-tete">
          <h2>La Société canadienne de la Croix-Rouge</h2>
        </div>
        <mat-list>
          <mat-list-item>Secourisme Général-RCR niveau C (janvier 2002) </mat-list-item>
          <mat-list-item>Renouvellement de la certification (janvier 2018)</mat-list-item>
        </mat-list>

        <div class="spacing en-tete">
          <h2>Institut Maritime du Québec à Montréal et Société de Sauvetage</h2>
        </div>
        <mat-list>
          <mat-list-item>Carte de conducteur d’embarcation de plaisance (avril 2001)</mat-list-item>
          <mat-list-item>Radiotéléphonie (VHF avril 2001)</mat-list-item>
          <mat-list-item>Endossement ASN novembre 2016</mat-list-item>
          <mat-list-item>Navigation côtière 1 (décembre 2001)</mat-list-item>
          <mat-list-item>Entretien des moteurs diesel (décembre 2006)</mat-list-item>
        </mat-list>
      </div>
    </div>

    <div fxLayoutAlign="center center">
      <mat-divider></mat-divider>
    </div>

    <div div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="50px">

      <div fxFlex="33" fxLayoutAlign="center center" fxHide.lt-md>
        <img class="side-image-small" src="../../../assets/cours.jpg" alt="Christian a la barre">
      </div>

      <div fxFlex>
        <div class="en-tete">
          <h2>2004 à ce jour</h2>
        </div>
        <mat-list>
          <mat-list-item> Instructeur en croisière élémentaire pour Voile Québec – Voile Canada </mat-list-item>
        </mat-list>

        <div class="spacing en-tete">
          <h2>2011 à ce jour</h2>
        </div>
        <mat-list>
          <mat-list-item>Formateur et conférencier sur des sujets variés </mat-list-item>
        </mat-list>

      </div>
    </div>



    <div div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="center center" fxLayoutGap="50px">
      <p>CV en version PDF</p>
      <button mat-raised-button color="primary" (click)="downloadCv();">Télécharger</button>
    </div>


  </div>
</div>