<svg class="svg-content" xmlns:osb="http://www.openswatchbook.org/uri/2009/osb" xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 154.458 153.6651">
  <defs>
    <radialGradient gradientUnits="userSpaceOnUse" r="245.71" cy="406.65" cx="371.43" id="e" gradientTransform="matrix(.75 0 0 .75145 -349.7464 130.5171)">
      <stop offset="0" stop-color="#009ca8" />
      <stop offset="1" stop-color="#2a7fff" />
    </radialGradient>
    <linearGradient id="a">
      <stop stop-color="#fff9f9" stop-opacity=".617" offset="0" />
      <stop stop-color="#d4d4d4" stop-opacity="0" offset="1" />
    </linearGradient>
    <filter id="i" color-interpolation-filters="sRGB">
      <feGaussianBlur stdDeviation="3.1275" />
    </filter>
    <filter id="l" color-interpolation-filters="sRGB">
      <feGaussianBlur stdDeviation="6.4665" />
    </filter>
    <radialGradient id="b" cx="99.286" cy="927.36" r="7.6429" gradientUnits="userSpaceOnUse">
      <stop stop-color="#a80" offset="0" />
      <stop stop-color="#ceaa1a" offset=".5" />
      <stop stop-color="#d7b426" offset=".75" />
      <stop stop-color="#e1be32" offset="1" />
    </radialGradient>
    <radialGradient id="c" cx="239.2" cy="932.18" r="3.3036" gradientTransform="matrix(1 0 0 1.3514 0 -327.52)"
      gradientUnits="userSpaceOnUse">
      <stop stop-color="#fff" stop-opacity=".4965" offset="0" />
      <stop stop-color="#fff" stop-opacity="0" offset="1" />
    </radialGradient>
    <filter id="o" color-interpolation-filters="sRGB">
      <feGaussianBlur stdDeviation=".0525" />
    </filter>
    <radialGradient id="d" cx="99.286" cy="927.36" r="7.1429" gradientUnits="userSpaceOnUse">
      <stop stop-opacity="0" offset="0" />
      <stop stop-opacity=".0993" offset=".7619" />
      <stop stop-opacity=".8511" offset="1" />
    </radialGradient>
    <radialGradient osb:paint="gradient" gradientTransform="matrix(.75 0 0 .75145 -349.7464 130.5171)" id="f" cx="371.43"
      cy="406.65" r="245.71" gradientUnits="userSpaceOnUse">
      <stop stop-color="#009ca8" offset="0" />
      <stop stop-color="#2a7fff" offset="1" />
    </radialGradient>
    <linearGradient id="j" x1="134.29" x2="625.73" y1="415.23" y2="415.23" gradientTransform="translate(-1.4286 31.429)"
      gradientUnits="userSpaceOnUse">
      <stop stop-color="#bc9912" offset="0" />
      <stop stop-color="#e7c74f" offset=".5" />
      <stop stop-color="#b09019" offset="1" />
    </linearGradient>
    <radialGradient id="k" cx="-292.75" cy="-21.158" r="245.72" gradientTransform="translate(663.07 469.73) scale(.98356)"
      gradientUnits="userSpaceOnUse">
      <stop stop-opacity="0" offset="0" />
      <stop stop-opacity=".0275" offset=".9683" />
      <stop stop-opacity=".7872" offset="1" />
    </radialGradient>
    <radialGradient r="7.6429" cy="927.36" cx="99.286" gradientUnits="userSpaceOnUse" id="m" xlink:href="#b" />
    <radialGradient r="3.3036" cy="932.18" cx="239.2" gradientTransform="matrix(1 0 0 1.3514 0 -327.52)" gradientUnits="userSpaceOnUse"
      id="n" xlink:href="#c" />
    <radialGradient r="7.1429" cy="927.36" cx="99.286" gradientUnits="userSpaceOnUse" id="p" xlink:href="#d" />
    <radialGradient r="7.6429" cy="927.36" cx="99.286" gradientUnits="userSpaceOnUse" id="q" xlink:href="#b" />
    <radialGradient r="3.3036" cy="932.18" cx="239.2" gradientTransform="matrix(1 0 0 1.3514 0 -327.52)" gradientUnits="userSpaceOnUse"
      id="r" xlink:href="#c" />
    <radialGradient r="7.1429" cy="927.36" cx="99.286" gradientUnits="userSpaceOnUse" id="s" xlink:href="#d" />
    <radialGradient r="7.6429" cy="927.36" cx="99.286" gradientUnits="userSpaceOnUse" id="t" xlink:href="#b" />
    <radialGradient r="3.3036" cy="932.18" cx="239.2" gradientTransform="matrix(1 0 0 1.3514 0 -327.52)" gradientUnits="userSpaceOnUse"
      id="u" xlink:href="#c" />
    <radialGradient r="7.1429" cy="927.36" cx="99.286" gradientUnits="userSpaceOnUse" id="v" xlink:href="#d" />
    <radialGradient r="7.6429" cy="927.36" cx="99.286" gradientUnits="userSpaceOnUse" id="w" xlink:href="#b" />
    <radialGradient r="3.3036" cy="932.18" cx="239.2" gradientTransform="matrix(1 0 0 1.3514 0 -327.52)" gradientUnits="userSpaceOnUse"
      id="x" xlink:href="#c" />
    <radialGradient r="7.1429" cy="927.36" cx="99.286" gradientUnits="userSpaceOnUse" id="y" xlink:href="#d" />
    <radialGradient r="7.6429" cy="927.36" cx="99.286" gradientUnits="userSpaceOnUse" id="z" xlink:href="#b" />
    <radialGradient r="3.3036" cy="932.18" cx="239.2" gradientTransform="matrix(1 0 0 1.3514 0 -327.52)" gradientUnits="userSpaceOnUse"
      id="A" xlink:href="#c" />
    <radialGradient r="7.1429" cy="927.36" cx="99.286" gradientUnits="userSpaceOnUse" id="B" xlink:href="#d" />
    <radialGradient r="7.6429" cy="927.36" cx="99.286" gradientUnits="userSpaceOnUse" id="C" xlink:href="#b" />
    <radialGradient r="3.3036" cy="932.18" cx="239.2" gradientTransform="matrix(1 0 0 1.3514 0 -327.52)" gradientUnits="userSpaceOnUse"
      id="D" xlink:href="#c" />
    <radialGradient r="7.1429" cy="927.36" cx="99.286" gradientUnits="userSpaceOnUse" id="E" xlink:href="#d" />
    <radialGradient r="7.6429" cy="927.36" cx="99.286" gradientUnits="userSpaceOnUse" id="F" xlink:href="#b" />
    <radialGradient r="3.3036" cy="932.18" cx="239.2" gradientTransform="matrix(1 0 0 1.3514 0 -327.52)" gradientUnits="userSpaceOnUse"
      id="G" xlink:href="#c" />
    <radialGradient r="7.1429" cy="927.36" cx="99.286" gradientUnits="userSpaceOnUse" id="H" xlink:href="#d" />
    <radialGradient r="7.6429" cy="927.36" cx="99.286" gradientUnits="userSpaceOnUse" id="I" xlink:href="#b" />
    <radialGradient r="3.3036" cy="932.18" cx="239.2" gradientTransform="matrix(1 0 0 1.3514 0 -327.52)" gradientUnits="userSpaceOnUse"
      id="J" xlink:href="#c" />
    <radialGradient r="7.1429" cy="927.36" cx="99.286" gradientUnits="userSpaceOnUse" id="K" xlink:href="#d" />
    <radialGradient gradientUnits="userSpaceOnUse" gradientTransform="matrix(1.846 -2.1007 2.15112 1.8903 -779.7634 13.3969)"
      r="184.6579" fy="443.4149" fx="106.6599" cy="443.4149" cx="106.6599" id="h" xlink:href="#e" />
    <linearGradient gradientTransform="translate(448.5714 5)" gradientUnits="userSpaceOnUse" y2="175.3646" x2="-70.6003"
      y1="622.5228" x1="-77.6024" id="g" xlink:href="#f" />
  </defs>
  <path d="M561.68 441.0943c0 101.9718-82.5075 184.6388-184.2825 184.6388S193.115 543.066 193.115 441.0943s82.5075-184.6388 184.2825-184.6388S561.68 339.1225 561.68 441.0943z"
    opacity=".74" fill="url(#g)" stroke="url(#h)" stroke-width=".7507" transform="matrix(.26458 0 0 .26458 -22.5197 -41.278)" />
  <path transform="matrix(.27652 0 0 .275 -26.0644 -47.0825)" d="M375.13 217.49c-127.81 0-231.43 103.62-231.43 231.43s103.62 231.43 231.43 231.43 231.43-103.62 231.43-231.43-103.62-231.43-231.43-231.43zm0 59.19c95.118 0 172.21 77.125 172.21 172.24 0 95.118-77.096 172.21-172.21 172.21-95.118 0-172.24-77.096-172.24-172.21 0-95.118 77.125-172.24 172.24-172.24z"
    filter="url(#i)" />
  <path d="M378.58 200.94c-135.7 0-245.72 110.01-245.72 245.72 0 135.7 110.01 245.72 245.72 245.72 135.7 0 245.72-110.01 245.72-245.72 0-135.7-110.01-245.72-245.72-245.72zm0 62.844c100.99 0 182.84 81.886 182.84 182.88s-81.855 182.84-182.84 182.84c-100.99 0-182.88-81.855-182.88-182.84 0-100.99 81.886-182.88 182.88-182.88z"
    fill="url(#j)" transform="matrix(.26458 0 0 .26458 -22.5197 -41.845)" />
  <path d="M375.13 207.24c-133.47 0-241.68 108.21-241.68 241.68 0 133.47 108.21 241.68 241.68 241.68 133.47 0 241.68-108.21 241.68-241.68 0-133.47-108.21-241.68-241.68-241.68zm0 61.81c99.329 0 179.84 80.539 179.84 179.87 0 99.329-80.509 179.84-179.84 179.84-99.329 0-179.87-80.509-179.87-179.84 0-99.329 80.539-179.87 179.87-179.87z"
    fill="url(#k)" filter="url(#l)" transform="matrix(.26458 0 0 .26458 -22.5197 -41.845)" />
  <path transform="matrix(.26458 0 0 .26458 50.4656 -224.9132)" d="M106.43 927.36c0 3.9449-3.198 7.1429-7.1429 7.1429s-7.1429-3.198-7.1429-7.1429 3.198-7.1428 7.1429-7.1428 7.1429 3.198 7.1429 7.1428z"
    fill="url(#m)" stroke="#000" stroke-width=".8" />
  <path d="M75.0665 21.3435l3.3073-1.6064" fill="none" stroke="#000" stroke-width=".502702" />
  <path transform="matrix(.26458 0 0 .26458 13.4557 -227.092)" d="M242.5 932.18c0 2.4656-1.4791 4.4643-3.3036 4.4643-1.8245 0-3.3036-1.9987-3.3036-4.4643s1.4791-4.4643 3.3036-4.4643c1.8245 0 3.3036 1.9987 3.3036 4.4643z"
    opacity=".476" fill="url(#n)" filter="url(#o)" />
  <path transform="matrix(.25259 0 0 .27244 51.6324 -232.1702)" d="M106.43 927.36c0 3.9449-3.198 7.1429-7.1429 7.1429s-7.1429-3.198-7.1429-7.1429 3.198-7.1428 7.1429-7.1428 7.1429 3.198 7.1429 7.1428z"
    fill="url(#p)" />
  <path transform="matrix(0 -.26458 .26458 0 -127.2165 64.6135)" d="M106.43 927.36c0 3.9449-3.198 7.1429-7.1429 7.1429s-7.1429-3.198-7.1429-7.1429 3.198-7.1428 7.1429-7.1428 7.1429 3.198 7.1429 7.1428z"
    fill="url(#q)" stroke="#000" stroke-width=".8" />
  <path d="M119.0403 40.0125l-1.6064-3.3073" fill="none" stroke="#000" stroke-width=".502702" />
  <path transform="matrix(0 -.26458 .26458 0 -129.3952 101.6234)" d="M242.5 932.18c0 2.4656-1.4791 4.4643-3.3036 4.4643-1.8245 0-3.3036-1.9987-3.3036-4.4643s1.4791-4.4643 3.3036-4.4643c1.8245 0 3.3036 1.9987 3.3036 4.4643z"
    opacity=".476" fill="url(#r)" filter="url(#o)" />
  <path transform="matrix(0 -.25259 .27244 0 -134.4734 63.4467)" d="M106.43 927.36c0 3.9449-3.198 7.1429-7.1429 7.1429s-7.1429-3.198-7.1429-7.1429 3.198-7.1428 7.1429-7.1428 7.1429 3.198 7.1429 7.1428z"
    fill="url(#s)" />
  <g>
    <path transform="matrix(.26458 0 0 .26458 9.9859 -132.309)" d="M106.43 927.36c0 3.9449-3.198 7.1429-7.1429 7.1429s-7.1429-3.198-7.1429-7.1429 3.198-7.1428 7.1429-7.1428 7.1429 3.198 7.1429 7.1428z"
      fill="url(#t)" stroke="#000" stroke-width=".8" />
    <path d="M34.5869 113.9477l3.3073-1.6064" fill="none" stroke="#000" stroke-width=".502702" />
    <path transform="matrix(.26458 0 0 .26458 -27.024 -134.4877)" d="M242.5 932.18c0 2.4656-1.4791 4.4643-3.3036 4.4643-1.8245 0-3.3036-1.9987-3.3036-4.4643s1.4791-4.4643 3.3036-4.4643c1.8245 0 3.3036 1.9987 3.3036 4.4643z"
      opacity=".476" fill="url(#u)" filter="url(#o)" />
    <path transform="matrix(.25259 0 0 .27244 11.1527 -139.566)" d="M106.43 927.36c0 3.9449-3.198 7.1429-7.1429 7.1429s-7.1429-3.198-7.1429-7.1429 3.198-7.1428 7.1429-7.1428 7.1429 3.198 7.1429 7.1428z"
      fill="url(#v)" />
  </g>
  <g>
    <path transform="matrix(0 .26458 -.26458 0 362.8311 86.7247)" d="M106.43 927.36c0 3.9449-3.198 7.1429-7.1429 7.1429s-7.1429-3.198-7.1429-7.1429 3.198-7.1428 7.1429-7.1428 7.1429 3.198 7.1429 7.1428z"
      fill="url(#w)" stroke="#000" stroke-width=".8" />
    <path d="M116.5744 111.3257l1.6063 3.3073" fill="none" stroke="#000" stroke-width=".502702" />
    <path transform="matrix(0 .26458 -.26458 0 365.0098 49.7148)" d="M242.5 932.18c0 2.4656-1.4791 4.4643-3.3036 4.4643-1.8245 0-3.3036-1.9987-3.3036-4.4643s1.4791-4.4643 3.3036-4.4643c1.8245 0 3.3036 1.9987 3.3036 4.4643z"
      opacity=".476" fill="url(#x)" filter="url(#o)" />
    <path transform="matrix(0 .25259 -.27244 0 370.088 87.8915)" d="M106.43 927.36c0 3.9449-3.198 7.1429-7.1429 7.1429s-7.1429-3.198-7.1429-7.1429 3.198-7.1428 7.1429-7.1428 7.1429 3.198 7.1429 7.1428z"
      fill="url(#y)" />
  </g>
  <g>
    <path transform="matrix(.26458 0 0 .26458 105.4592 -168.3718)" d="M106.43 927.36c0 3.9449-3.198 7.1429-7.1429 7.1429s-7.1429-3.198-7.1429-7.1429 3.198-7.1428 7.1429-7.1428 7.1429 3.198 7.1429 7.1428z"
      fill="url(#z)" stroke="#000" stroke-width=".8" />
    <path d="M130.0602 77.885l3.3073-1.6064" fill="none" stroke="#000" stroke-width=".502702" />
    <path transform="matrix(.26458 0 0 .26458 68.4493 -170.5505)" d="M242.5 932.18c0 2.4656-1.4791 4.4643-3.3036 4.4643-1.8245 0-3.3036-1.9987-3.3036-4.4643s1.4791-4.4643 3.3036-4.4643c1.8245 0 3.3036 1.9987 3.3036 4.4643z"
      opacity=".476" fill="url(#A)" filter="url(#o)" />
    <path transform="matrix(.25259 0 0 .27244 106.626 -175.6287)" d="M106.43 927.36c0 3.9449-3.198 7.1429-7.1429 7.1429s-7.1429-3.198-7.1429-7.1429 3.198-7.1428 7.1429-7.1428 7.1429 3.198 7.1429 7.1428z"
      fill="url(#B)" />
  </g>
  <g>
    <path transform="matrix(0 -.26458 .26458 0 -221.7098 103.203)" d="M106.43 927.36c0 3.9449-3.198 7.1429-7.1429 7.1429s-7.1429-3.198-7.1429-7.1429 3.198-7.1428 7.1429-7.1428 7.1429 3.198 7.1429 7.1428z"
      fill="url(#C)" stroke="#000" stroke-width=".8" />
    <path d="M24.547 78.602l-1.6064-3.3073" fill="none" stroke="#000" stroke-width=".502702" />
    <path transform="matrix(0 -.26458 .26458 0 -223.8885 140.2129)" d="M242.5 932.18c0 2.4656-1.4791 4.4643-3.3036 4.4643-1.8245 0-3.3036-1.9987-3.3036-4.4643s1.4791-4.4643 3.3036-4.4643c1.8245 0 3.3036 1.9987 3.3036 4.4643z"
      opacity=".476" fill="url(#D)" filter="url(#o)" />
    <path transform="matrix(0 -.25259 .27244 0 -228.9667 102.0362)" d="M106.43 927.36c0 3.9449-3.198 7.1429-7.1429 7.1429s-7.1429-3.198-7.1429-7.1429 3.198-7.1428 7.1429-7.1428 7.1429 3.198 7.1429 7.1428z"
      fill="url(#E)" />
  </g>
  <g>
    <path transform="matrix(.26458 0 0 .26458 12.8208 -206.9586)" d="M106.43 927.36c0 3.9449-3.198 7.1429-7.1429 7.1429s-7.1429-3.198-7.1429-7.1429 3.198-7.1428 7.1429-7.1428 7.1429 3.198 7.1429 7.1428z"
      fill="url(#F)" stroke="#000" stroke-width=".8" />
    <path d="M37.4218 39.2982l3.3073-1.6064" fill="none" stroke="#000" stroke-width=".502702" />
    <path transform="matrix(.26458 0 0 .26458 -24.189 -209.1373)" d="M242.5 932.18c0 2.4656-1.4791 4.4643-3.3036 4.4643-1.8245 0-3.3036-1.9987-3.3036-4.4643s1.4791-4.4643 3.3036-4.4643c1.8245 0 3.3036 1.9987 3.3036 4.4643z"
      opacity=".476" fill="url(#G)" filter="url(#o)" />
    <path transform="matrix(.25259 0 0 .27244 13.9876 -214.2155)" d="M106.43 927.36c0 3.9449-3.198 7.1429-7.1429 7.1429s-7.1429-3.198-7.1429-7.1429 3.198-7.1428 7.1429-7.1428 7.1429 3.198 7.1429 7.1428z"
      fill="url(#H)" />
  </g>
  <g>
    <path transform="matrix(.26458 0 0 .26458 50.4656 -112.8437)" d="M106.43 927.36c0 3.9449-3.198 7.1429-7.1429 7.1429s-7.1429-3.198-7.1429-7.1429 3.198-7.1428 7.1429-7.1428 7.1429 3.198 7.1429 7.1428z"
      fill="url(#I)" stroke="#000" stroke-width=".8" />
    <path d="M75.0665 133.413l3.3073-1.6063" fill="none" stroke="#000" stroke-width=".502702" />
    <path transform="matrix(.26458 0 0 .26458 13.4557 -115.0223)" d="M242.5 932.18c0 2.4656-1.4791 4.4643-3.3036 4.4643-1.8245 0-3.3036-1.9987-3.3036-4.4643s1.4791-4.4643 3.3036-4.4643c1.8245 0 3.3036 1.9987 3.3036 4.4643z"
      opacity=".476" fill="url(#J)" filter="url(#o)" />
    <path transform="matrix(.25259 0 0 .27244 51.6324 -120.1006)" d="M106.43 927.36c0 3.9449-3.198 7.1429-7.1429 7.1429s-7.1429-3.198-7.1429-7.1429 3.198-7.1428 7.1429-7.1428 7.1429 3.198 7.1429 7.1428z"
      fill="url(#K)" />
  </g>

  <g class="animation">

    <g class="animation-1" [class.show]="animation[0] == true" font-weight="400" font-family="sans-serif" letter-spacing="0"
      word-spacing="0"><text y="49.2611" x="45.0254" style="line-height:1.25;-inkscape-font-specification:'sans-serif, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:start"
        font-size="9.1722" stroke-width=".2646" transform="translate(-2.6458 12.8512)">
        <tspan y="49.2611" x="45.0254">
          <tspan y="49.2611" x="45.0254">+7 000 heures</tspan>
        </tspan>
      </text><text y="62.769" x="33.0733" style="line-height:1.25;-inkscape-font-specification:'sans-serif, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:start"
        font-size="9.1722" stroke-width=".2646" transform="translate(-2.6458 12.8512)">
        <tspan y="62.769" x="33.0733">
          <tspan style="-inkscape-font-specification:'sans-serif, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:start"
            y="62.769" x="33.0733">+5 000 milles marin</tspan>
        </tspan>
      </text><text y="510.3646" x="243.6854" style="line-height:1.25;-inkscape-font-specification:'sans-serif, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:start"
        transform="matrix(.26458 0 0 .26458 -22.5197 -41.278)" font-size="29.3333">
        <tspan y="510.3646" x="243.6854">VOILE CROISIÈRE</tspan>
      </text></g>

    <g class="animation-2" [class.show]="animation[1] == true" font-weight="400" font-family="sans-serif" letter-spacing="0" word-spacing="0"><text y="48.3161"
        x="42.3795" style="line-height:1.25;-inkscape-font-specification:'sans-serif, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:start"
        font-size="9.1722" stroke-width=".2646" transform="translate(-2.6458 12.8512)">
        <tspan y="48.3161" x="42.3795">
          <tspan y="48.3161" x="42.3795">+150 personnes</tspan>
        </tspan>
      </text><text y="60.8791" x="62.3664" style="line-height:1.25;-inkscape-font-specification:'sans-serif, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:start"
        font-size="9.1722" stroke-width=".2646" transform="translate(-2.6458 12.8512)">
        <tspan y="60.8791" x="62.3664">
          <tspan style="-inkscape-font-specification:'sans-serif, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:start"
            y="60.8791" x="62.3664">formées</tspan>
        </tspan>
      </text><text transform="matrix(.26458 0 0 .26458 -22.5197 -41.278)" style="line-height:1.25;-inkscape-font-specification:'sans-serif, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:start"
        x="243.6854" y="510.3646" font-size="29.3333">
        <tspan x="243.6854" y="510.3646">VOILE CROISIÈRE</tspan>
      </text></g>

    <g class="animation-3" [class.show]="animation[2] == true" font-weight="400" font-family="sans-serif" letter-spacing="0" word-spacing="0" stroke-width=".2646"><text
        y="59.3542" x="59.5775" style="line-height:1.25;-inkscape-font-specification:'sans-serif, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:start"
        font-size="9.8778" transform="translate(-2.6458 12.8512)">
        <tspan y="0" x="80.2398">
          <tspan y="59.3542" x="59.5775">+</tspan>
          <tspan y="59.3542" x="67.661">35</tspan>
          <tspan y="59.3542" x="80.2398"> ans</tspan>
        </tspan>
      </text><text y="75.5079" x="47.0584" style="line-height:1.25;-inkscape-font-specification:'sans-serif, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:start"
        font-size="9.1722" transform="translate(-2.6458 12.8512)">
        <tspan y="75.5079" x="47.0584">
          <tspan style="-inkscape-font-specification:'sans-serif, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:start"
            y="75.5079" x="47.0584" font-size="9.8778">D&apos;EXPÉRIENCE</tspan>
        </tspan>
      </text></g>
  </g>

</svg>