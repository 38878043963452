import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
    selector: 'app-contact-module',
    templateUrl: './contact-module.component.html',
    styleUrls: ['./contact-module.component.css']
})
export class ContactModuleComponent implements OnInit {


    constructor(private http: HttpClient,
        private snackBar: MatSnackBar
    ) { }

    ngOnInit() {
    }

    openSnackBar() {
        this.snackBar.open("Votre message a été envoyé!", "Ok", {
            duration: 3000,
        });
    }

    openErrorSnackBar() {
        this.snackBar.open("L'envoie n'a pas fonctionné!", "Ok", {
            duration: 3000,
        });
    }

    onSubmit(form: NgForm) {

        var data = {
            to: "plaisance@c-led.ca",
            reply: form.value.email,
            subject: form.value.subject,
            text: form.value.text,
            captcha: form.value.captcha
        }

        this.http.post<any>('https://api.bitwiz.ca/email', data, { observe: 'response' }).subscribe((response) => {

            console.log(response);

            if (response.status == 200) {
                this.openSnackBar();
            } else {
                this.openErrorSnackBar();
            }

            form.resetForm();
        })
    }

}
