import { Component, OnInit, ViewChildren } from '@angular/core';
import { GoogleService } from '../../services/google.service';



@Component({
  selector: 'app-publications',
  templateUrl: './publications.component.html',
  styleUrls: ['./publications.component.css']
})
export class PublicationsComponent implements OnInit {
  @ViewChildren('facebookCard') fbCard: any;
  facebookDataSource;

  constructor(private googleService: GoogleService, ) {



  }

  ngOnInit() {
    this.googleService.getDocument("Articles Facebook").subscribe((data) => {
      var dict = data.values.slice(1).map(row => row.reduce(function (acc, cur, i) {
        acc[data.values[0][i]] = cur;
        return acc;
      }, {}));
      this.facebookDataSource = dict;
    });
  }

  ngAfterViewInit() {

    this.fbCard.changes.subscribe(t => {
      if (window['FB']) {
        window['FB'].XFBML.parse(document.getElementById('social-facebook'));
      }
    })

  }

}
