import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';
import { HttpClientModule } from "@angular/common/http";
import { OAuthModule } from "angular-oauth2-oidc";
import { FormsModule }   from '@angular/forms';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';

registerLocaleData(localeFr, 'fr');

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OverlayModule } from '@angular/cdk/overlay';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { ScrollEventModule } from 'ngx-scroll-event';
import { LayoutModule } from '@angular/cdk/layout';

import {FlexLayoutModule} from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material/button';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatTabsModule} from '@angular/material/tabs';
import {MatDividerModule} from '@angular/material/divider';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatChipsModule} from '@angular/material/chips';
import {MatInputModule} from '@angular/material/input';
import {MatSnackBarModule} from '@angular/material/snack-bar';

import { FooterComponent } from './components/footer/footer.component';
import { AccueilComponent } from './components/accueil/accueil.component';
import { MainNavComponent } from './components/main-nav/main-nav.component';
import { PublicationsComponent } from './components/publications/publications.component';
import { InstructeurComponent } from './components/instructeur/instructeur.component';
import { EntrepriseComponent } from './components/entreprise/entreprise.component';
import { FormationsComponent } from './components/formations/formations.component';
import { PortholeAnimationComponent } from './components/porthole-animation/porthole-animation.component';
import { ConfidentialiteComponent } from './components/confidentialite/confidentialite.component';
import { ContactModuleComponent } from './components/contact-module/contact-module.component';
import { ContactezNousComponent } from './components/contactez-nous/contactez-nous.component';

const appRoutes: Routes = [
  { path: '', component: AccueilComponent },
  { path: 'instructeur', component: InstructeurComponent },
  { path: 'entreprise', component: EntrepriseComponent },
  { path: 'publications', component: PublicationsComponent },
  { path: 'formations', component: FormationsComponent },
  { path: 'confidentialite', component: ConfidentialiteComponent },
  { path: 'contactez-nous', component: ContactezNousComponent },
]

registerLocaleData(localeFr);

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled',
};


@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    AccueilComponent,
    MainNavComponent,
    PublicationsComponent,
    InstructeurComponent,
    EntrepriseComponent,
    FormationsComponent,
    PortholeAnimationComponent,
    ConfidentialiteComponent,
    ContactModuleComponent,
    ContactezNousComponent,
  ],
  imports: [
    FlexLayoutModule,
    MatButtonModule,
    MatListModule,
    MatIconModule,
    MatCardModule,
    MatFormFieldModule,
    MatTabsModule,
    MatDividerModule,
    MatToolbarModule,
    MatSidenavModule,
    MatChipsModule,
    MatInputModule,
    MatSnackBarModule,
    FormsModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    RouterModule.forRoot(appRoutes, routerOptions),
    OAuthModule.forRoot(),
    BrowserAnimationsModule,
    OverlayModule,
    ScrollToModule.forRoot(),
    ScrollEventModule,
    LayoutModule,
    DeviceDetectorModule.forRoot(),
    RecaptchaModule,
    RecaptchaFormsModule
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'fr' }],
  bootstrap: [AppComponent,],

})
export class AppModule { }
