<mat-toolbar class="footer fixed-bottom">

  <mat-divider></mat-divider>
  <mat-toolbar-row fxLayout="column" fxLayoutAlign="center center">

    <div class="media-wrapper" fxLayout="row" fxLayoutAlign="space-evenly center" >
      <a mat-button href="https://www.facebook.com/clednavigationdeplaisance/">Facebook</a>
      <a mat-button href="https://www.youtube.com/channel/UCMoruQbZEjtDGK3vXBtFeZQ?view_as=subscriber">Youtube</a>
      <a mat-button href="https://www.linkedin.com/in/christian-leduc-2848a629/">LinkedIn</a>
      <a mat-button href="https://www.eventbrite.ca/o/christian-leduc-c-led-navigation-de-plaisance-17753585218">EventBrite</a>
      <a mat-button href="https://elearning.c-led.ca/">Didacte</a>
    </div>

    <a mat-button href="#" [routerLink]="['confidentialite']">Politique de confidentialité</a>

    <div class="small_writing" fxLayout="column" fxLayoutAlign="center center" fxFlex="auto">
      <p>&copy; {{ year }} C-LED Navigation de plaisance. Tous droits réservés. </p>
      <p>Tout le matériel contenu dans ce site est la propriété de C-LED Navigation de plaisance.</p>
      <p>Si vous avez des questions à propos de notre site ou de nos
        services, <a [routerLink]="['contactez-nous']">contactez-nous</a> directement.</p>
    </div>


  </mat-toolbar-row>

</mat-toolbar>