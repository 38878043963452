import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs/Rx';

// https://www.eventbrite.com/platform/docs/events
const eventbrite_token = "OSTAWLD35LHV7LC33JUX"; //private token from 
const organisation_id = "156087869950"

@Injectable({
  providedIn: 'root'
})
export class EventbriteService {

  constructor(private http: HttpClient) { }

  getEvents(): Observable<any> {


    return this.http.get<any>("https://www.eventbriteapi.com/v3/organizations/" + organisation_id + "/events/?status=live", { 
      headers: new HttpHeaders({
        "authorization": "Bearer " + eventbrite_token
      }) });
  }

}