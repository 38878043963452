import { Component, OnInit } from '@angular/core';
import { GoogleService } from '../../services/google.service';
import { EventbriteService } from '../../services/eventbrite.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


@Component({
  selector: 'app-formations',
  templateUrl: './formations.component.html',
  styleUrls: ['./formations.component.css']
})
export class FormationsComponent implements OnInit {

  cromDataSource;
  conferenceDataSource;
  youtubeDataSource = [];
  formationDataSource;
  radio;
  tempete;

  eventBrite = [];
  vhf_events;


  selected_vhf = 0;
  selected_conf = 0;

  safeHtml: SafeHtml;

  constructor(private googleService: GoogleService,
    private eventbrite: EventbriteService,
    private sanitizer: DomSanitizer  ) {


  }

  ngOnInit() {


    this.googleService.getDocument("CRO(M)").subscribe((data) => {

      var dict = data.values.slice(1).map(row => row.reduce(function (acc, cur, i) {
        acc[data.values[0][i]] = cur;
        return acc;
      }, {}));
      this.cromDataSource = dict;
    });

    this.googleService.getDocument("Conferences").subscribe((data) => {
      var dict = data.values.slice(1).map(row => row.reduce(function (acc, cur, i) {
        acc[data.values[0][i]] = cur;
        return acc;
      }, {}));

      
      this.conferenceDataSource = dict;
    });

    this.googleService.getDocument("Vidéos YouTube").subscribe((data) => {
      var dict = data.values.slice(1).map(row => row.reduce(function (acc, cur, i) {
        acc[data.values[0][i]] = cur;
        return acc;
      }, {}));
      this.youtubeDataSource = dict;
      this.radio = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/" + this.youtubeDataSource[0].id + "?modestbranding=1&rel=0&showinfo=0");
      this.tempete = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/" + this.youtubeDataSource[1].id + "?modestbranding=1&rel=0&showinfo=0");
    });

    this.googleService.getDocument("EventBrite").subscribe((data) => {
      var dict = data.values.slice(1).map(row => row.reduce(function (acc, cur, i) {
        acc[data.values[0][i]] = cur;
        return acc;
      }, {}));

      for (let sufix of dict) {
        this.eventBrite.push({
          id: sufix.id,
          events: []
        })
      }

      this.googleService.getDocument("Formation").subscribe((data) => {

        var dict = data.values.slice(1).map(row => row.reduce(function (acc, cur, i) {
          acc[data.values[0][i]] = cur;
          return acc;
        }, {}));

        this.formationDataSource = '';
        for (let elem in dict) {
          if (dict[elem].visibility == 1) {
            this.formationDataSource = this.sanitizer.bypassSecurityTrustHtml('<p>' + dict[elem].text + '</p>');
          }
        }

      });

      this.eventbrite.getEvents().subscribe((data) => {

        for (let i in data.events) {
          if (data.events[i].status == "live") {
            let eventNameSufix = data.events[i].name.text.split(' ');
            eventNameSufix = eventNameSufix[eventNameSufix.length - 1];

            eventNameSufix = eventNameSufix.replace('(', '');
            eventNameSufix = eventNameSufix.replace(')', '');

            for (let y in this.eventBrite) {
              if (this.eventBrite[y].id == eventNameSufix) {
                this.eventBrite[y].events.push(data.events[i]);
                break;
              }
            }
          }
        }
      })

    });



  }


  getEventBrite(sufix) {
    let events;
    for (let i in this.eventBrite) {
      if (this.eventBrite[i].id == sufix) {
        return this.eventBrite[i].events;
      }
    }
    return '';
  }

}