<div class="container">
  <div class="content" fxLayout="column" fxLayoutGap="15%">

    <!-- ----------------------------------- VOILE ------------------------------------------- -->
    <div id="voile" class="formation-section"  fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center" fxLayoutGap="5%">
      <div>
        <img class="side-image" src="../../../assets/groupe.jpg" alt="Groupe École de voile Gilles Thetrault" />
      </div>

      <div fxFlex="66">
        <div class="en-tete">
          <h1>Formation initiation en voile croisière</h1>
          <h2>Voile Québec – Voile Canada</h2>
        </div>
        <p>
          Ces formations sont réalisées sur des quillards de 22 et 23 pieds et
          impliquent quatre stagiaires pendant trois jours.
        </p>
        <p>
          L'objectif de cette formation consiste à enseigner les rudiments de la
          voile croisière élémentaire. Pour ce faire, M. Leduc doit créer un
          contexte propice à l'apprentissage de ce sport par les stagiaires et
          doit s'assurer que le tout se déroule de façon sécuritaire pour
          l'équipage ainsi que pour l'embarcation.
        </p>
        <span>Les trois principaux rôles joués par M. Leduc lors de ces formations
          consistent à :</span>
        <mat-list>
          <mat-list-item>Agir comme chef de bord</mat-list-item>
          <mat-list-item>Enseigner la matière requise en favorisant l'exécution des
            manœuvres par les stagiaires
          </mat-list-item>
          <mat-list-item>Animer la formation de façon à favoriser un bon contexte
            d’apprentissage</mat-list-item>
        </mat-list>

        <div [innerHTML]='formationDataSource'></div>

      </div>
    </div>

    <!-- ----------------------------------- RADIO ------------------------------------------- -->
      <div class="formation-section"  fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center start" fxLayoutGap="5%">
        <div class="visual">
          <img class="side-image" src="../../../assets/vhf.jpg" alt="Radio vhf"
            *ngIf="youtubeDataSource[0]?.visibility==0">
          <iframe *ngIf="youtubeDataSource[0]?.visibility==1" width="560" height="315" [src]="radio" frameborder=" 0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
  
        <div fxFlex="66">
          <mat-tab-group [selectedIndex]="selected_vhf">
            <mat-tab label="Formation">
              <h1 class="tab-title">Formation en ligne en radio maritime CRO(M)</h1>
              <p>
                Cette formation en ligne (quand et où vous voulez) a pour objectif de vous permettre d’apprendre
                comment placer des appels de routine et d’urgence avec un appareil
                radio maritime VHF comme on en retrouve sur les embarcations de
                plaisance et les navires commerciaux. Ces appareils sont
                spécifiquement destinés aux communications sur les fréquences
                désignées du service maritime. Ils vous permettent de communiquer
                avec d’autres embarcations ou d’obtenir de l’aide lors d’une
                situation d’urgence en mer.
              </p>
              <span>Vous y apprendrez :</span>
              <mat-list>
                <mat-list-item>Comment opérer un appareil radio maritime;</mat-list-item>
                <mat-list-item>Comment les appareils &lt; se branchent &gt; les uns aux
                  autres;</mat-list-item>
                <mat-list-item>Les procédures d'appels de routine, d'appels de détresse et les
                  procédures utilisant l'appel sélectif numérique (ASN) pour les
                  communications numériques et vocales;</mat-list-item>
                <mat-list-item>Les lois et règlements qui régissent la radio
                  maritime;</mat-list-item>
                <mat-list-item>Les autres types d'exigences et d'équipement de sécurité en mer
                  tels que requis par le système mondial de détresse et de
                  sécurité en mer (SMDSM).</mat-list-item>
              </mat-list>
              <p>
                Prendre note que la réussite de l'examen vous permettra d'obtenir
                votre certificat restreint d’opérateur maritime CRO(M), qui est
                valide à vie.
              </p>
              <p>
                Vidéos, plusieurs photos et explications sont au rendez-vous afin
                de faciliter l’apprentissage de la matière enseignée.
              </p>
  
              <div fxLayoutAlign="center center">
                <button mat-flat-button color="primary" fxLayoutAlign="center" (click)="this.selected_vhf=2;">Réservez
                  maintenant !</button>
              </div>
            </mat-tab>
  
            <mat-tab label="Prix">
              <h1 class="tab-title">Choisissez l'option qui vous convient</h1>
              <div class="event_item" *ngFor="let item of cromDataSource">
                <div class="event_description" fxLayout="column" *ngIf="item.visibility == 1">
                  <h4>{{ item.name }}</h4>
                  <p>{{ item.price }} {{ item.modalities }}</p>
                </div>
              </div>
            </mat-tab>
  
            <mat-tab label="Achat">
  
              <div class="eventbrite-default" fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="20px"
                *ngIf="getEventBrite('F01') == 0">
  
                <h3>Voyez les dates et achetez vos billets dès maintenant sur Eventbrite!</h3>
                <div>
                  <a mat-flat-button color="primary" fxLayoutAlign="center"
                    href="https://www.eventbrite.ca/o/christian-leduc-c-led-navigation-de-plaisance-17753585218">Achetez
                    ici</a>
                </div>
              </div>
  
              <div class="event_list">
                <div fxLayout="row" class="event_item" fxLayout.lt-sm="column" fxLayoutAlign="start center">
                  <mat-icon class="event_logo" color="primary">laptop</mat-icon>
                  <div class="event_description">
                    <h4>Formation radio maritime - En ligne</h4>
                    <p>En tout temps!</p>
                  </div>
  
                  <div fxLayoutAlign="end end">
                    <a mat-stroked-button color="primary" fxLayoutAlign="center" href="https://elearning.c-led.ca/" >Réservez
                      maintenant</a>
                  </div>
                </div>
  
                <mat-divider class="event_divider"></mat-divider>
              </div>
  
  
              <div class="event_list" *ngFor="let event of getEventBrite('F01')">
                <div fxLayout="row" class="event_item" fxLayout.lt-sm="column" fxLayoutAlign="start center">
                  <mat-icon class="event_logo" color="primary">event_available</mat-icon>
                  <div class="event_description">
                    <h4>{{ event.name.text }}</h4>
                    <p>{{ event.start.local | date: "d MMMM y, h:mm a" }}</p>
                  </div>
  
                  <div fxLayoutAlign="center end">
                    <a mat-stroked-button color="primary" fxLayoutAlign="center" href="{{ event.url }}">Réservez
                      maintenant</a>
                  </div>
                </div>
  
                <mat-divider class="event_divider"></mat-divider>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>

   

    <!-- ----------------------------------- CONFERENCE ------------------------------------------- -->
    <div class="formation-section" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center start" fxLayoutGap="5%">
      <div class="visual">
        <img *ngIf="youtubeDataSource[1]?.visibility==0" class="side-image" src="../../../assets/tempete.jpg"
          alt="Tempête Irene" />
        <iframe *ngIf="youtubeDataSource[1]?.visibility==1" width="560" height="315" [src]="tempete" frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>

      <div fxFlex="66">
        <mat-tab-group [selectedIndex]="selected_conf">
          <mat-tab label="Formation">
            <h1 class="tab-title">Conférence « Tempêtes à l’horizon 2.0 »</h1>
            <p>
              C'est l’histoire vécue de deux voiliers, Boudoux et Philosoft, et
              leurs équipages qui se sont retrouvés sur le passage des ouragans
              Irene en 2011, Irma en 2017 et Dorian en 2019.
            </p>

            <p>
              Pour les plaisanciers navigants aux Bahamas, dans les Antilles,
              dans les Maritimes, sur le lac Champlain, les Grands Lacs ainsi
              que sur le fleuve et le golfe St-Laurent, ces ouragans peuvent
              même nous atteindre sous la forme de tempêtes post-tropicales.
            </p>

            <p>
              Si vous vous retrouviez à bord de votre embarcation dans de telles
              conditions, sauriez-vous comment réagir? Cette conférence vous
              explique comment préparer votre embarcation et l’équipage afin
              d’affronter le déchainement des éléments.
            </p>

            <p>
              Par la présentation du passage de la tempête post-tropicale Irene
              au lac Champlain – 72 heures en 180 minutes –, je vous fais vivre
              mon expérience à bord du voilier Philosoft.
            </p>

            <p>
              Vous apprendrez également comment s’est vécu le passage d’Irma et
              de Dorian à bord de ces mêmes deux voiliers.
            </p>

            <p>
              À l’aide de vidéos et de nombreuses photos, cette présentation
              aborde la préparation du voilier, le passage de ces tempêtes vécu
              à bord ainsi que le retour à la marina et la constatation de
              l’ampleur des dommages qu’ont subis plusieurs voiliers et marinas.
            </p>
            <div fxLayoutAlign="center center">
              <button mat-flat-button color="primary" fxLayoutAlign="center" (click)="this.selected_conf=2;">Réservez
                maintenant !</button>
            </div>
          </mat-tab>

          <mat-tab label="Prix">
            <h1 class="tab-title">Choisissez l'option qui vous convient</h1>
            <div class="event_item" *ngFor="let item of conferenceDataSource">
              <div class="event_description" fxLayout="column" *ngIf="item.visibility == 1">
                <h4>{{ item.name }}</h4>
                <p>{{ item.price }} {{ item.modalities }}</p>
              </div>
            </div>
          </mat-tab>

          <mat-tab label="Achat">
            <div class="eventbrite-default" fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="20px"
              *ngIf="getEventBrite('C01') == 0">
              <h3>Voyez les dates et achetez vos billets dès maintenant sur Eventbrite!</h3>
              <div>
                <a mat-flat-button color="primary" fxLayoutAlign="center"
                  href="https://www.eventbrite.ca/o/christian-leduc-c-led-navigation-de-plaisance-17753585218">Achetez
                  ici</a>
              </div>
            </div>

            <div class="event_list" *ngFor="let event of getEventBrite('C01')">
              <div fxLayout="row" class="event_item" fxLayout.lt-sm="column" fxLayoutAlign="start center">
                <mat-icon class="event_logo" color="primary">event_available</mat-icon>
                <div class="event_description">
                  <h4>{{ event.name.text }}</h4>
                  <p>{{ event.start.local | date: "d MMMM y, h:mm a" }}</p>
                </div>

                <div fxLayoutAlign="center end">
                  <a mat-stroked-button color="primary" fxLayoutAlign="center" href="{{ event.url }}">Réservez
                    maintenant</a>
                </div>
              </div>

              <mat-divider class="event_divider"></mat-divider>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>


  </div>
</div>