<div (onScroll)="scrollHandler($event)">
  <mat-sidenav-container class="sidenav-container">

    <mat-sidenav #drawer class="sidenav" fixedInViewport="false" [attr.role]="(isHandset | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset | async) ? 'over' : 'side'" [opened]="!(isHandset | async)" [ngClass]="{hidden: !( isHandset | async)}">

      <mat-toolbar color="primary">Menu</mat-toolbar>

      <mat-nav-list>
        <a mat-list-item *ngFor="let link of links" class="side_menu" [routerLink]="[link.ref]">{{ link.name }}</a>
      </mat-nav-list>

    </mat-sidenav>

    <mat-sidenav-content>
      <mat-toolbar color="primary" [@slideInOut]='state'>
        <mat-toolbar-row class="mat-elevation-z5 main_toolbar">
          <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()" *ngIf="isHandset | async">
            <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
          </button>
          <a href="#" [routerLink]="['']">
            <img class="logo" src="../../../assets/cled_logo_top.png" alt="Logo C-LED Navigation de plaisance">
          </a>
          <span class="spacer"></span>

          <div class="nav-link-box" fxLayout="row" [routerLinkActive]="['router-active']">
            <div class="nav-link" fxLayout="column" fxLayoutAlign="center center" [ngClass]="{hidden: ( isHandset | async)}"
              *ngFor="let link of links" [routerLinkActive]="['active']">

              <a [routerLink]="[link.ref]" [ngClass]="{hidden: ( isHandset | async)}">
                {{ link.name }}
              </a>
            </div>
          </div>
        </mat-toolbar-row>

        <mat-toolbar-row *ngIf="promotions && router.url === '/'" class="promotion_toolbar" fxLayout="row"
          fxLayoutAlign="center center">

          <div class="marquee">
            <span class="marquee_container animation">
              <ng-container *ngFor="let item of promotionsDataSource">
                <span class="marquee_text" *ngIf="item.visibility == 1">

                  <ng-container *ngIf="item.url;else noLink">
                    <a href="#" [routerLink]="[item.url]" [fragment]="[item.fragment]">{{item.promo}}</a>
                  </ng-container>
                  <ng-template #noLink>{{item.promo}}</ng-template>
                </span>
              </ng-container>
            </span>
          </div>

        </mat-toolbar-row>


      </mat-toolbar>

      <ng-content></ng-content>

    </mat-sidenav-content>

  </mat-sidenav-container>
</div>