<!-- Top image -->
<div class="container_vh bg-image" fxLayout="row" fxLayoutAlign="end none" >

  <div fxFlex="33" fxLayout="column">
    <div class="intro">
      <h1>« On peut faire des affaires avec tout le monde,
        mais on ne peut naviguer qu’avec un gentilhomme. »</h1>
      <p>Hommage à la voile - 2005</p>
    </div>

  </div>
</div>

<!-- Card section -->
<div class="container">
  <div class="content" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="50px">

    
    <div class="mat-card-section">

              <!-- E-learning -->
              <mat-card class="nav-card" class="mat-elevation-z8">
                <mat-card-header>
                  <mat-card-title><h2>Espace E-Learning</h2></mat-card-title>
                  <mat-card-subtitle>Des formations et conférences professionnelles en ligne.</mat-card-subtitle>
                </mat-card-header>
        
                <div class="card_content">
                  <div class="image-container">
                    <img class="card-image" src="../../../assets/porthole_elearning.jpg" alt="Formation et conférences en ligne">
                  </div>
        
        
                  <mat-card-content>
                    <p>
                      Des solutions d'apprentissage 100% à distance et accessible 24h/7. 
                      Vous n'avez plus besoin de vous déplacer dans des salles de classe: 
                      suivez la formation à votre rythme de chez vous ou du bureau, avec une simple connexion internet.
                    </p>

                    <mat-chip-list>
                      <mat-chip><a href="#" routerLink="formations">Formation en radio maritime</a></mat-chip>
                    </mat-chip-list>
        
                  </mat-card-content>
                  <mat-card-actions fxLayout="row" fxLayoutAlign="end none">
                    <a mat-stroked-button color="primary" href="https://elearning.c-led.ca/">En savoir
                      plus...</a>
                  </mat-card-actions>
                </div>
              </mat-card>
              <!-- END E-learning -->

      <!-- L'entreprise -->
      <mat-card class="nav-card nav-card-middle" class="mat-elevation-z5">
        <mat-card-header>
          <mat-card-title fxLayoutAlign="center center">
            <h2>L'entreprise</h2>
          </mat-card-title>
        </mat-card-header>

        <div class="card_content">

          <div class="image-container">
            <img class="card-image" src="../../../assets/cled_logo_square.svg" alt="Logo C-LED Navigation de plaisance">
          </div>

          <mat-card-content>
            <p>Mettre à votre service le savoir, le savoir-faire et le savoir-être (sens marin) de l’entreprise pour
              tout
              ce qui concerne la pratique de la voile en toute sécurité pour l’équipage et l’embarcation.</p>

          </mat-card-content>
          <mat-card-actions fxLayout="row" fxLayoutAlign="end none">
            <button mat-stroked-button color="primary" routerLink="entreprise">En savoir
              plus...</button>
          </mat-card-actions>
          
        </div>
      </mat-card>
      <!-- END L'entreprise -->
    </div>


    <!-- Middle block -->
    <div class="mat-card-section" fxLayout="column" fxLayoutGap="50px">

      
      <!-- L"instructeur -->
      <mat-card class="nav-card" class="mat-elevation-z8">
        <mat-card-header>
          <mat-card-title fxLayoutAlign="center center"><h2>Découvrez l’instructeur</h2></mat-card-title>

        </mat-card-header>

        <div class="card_content">
          <div class="image-container">
            <app-porthole-animation class="card-image"></app-porthole-animation>
          </div>


          <mat-card-content>
            <p>Un instructeur de voile croisière, Radio maritime CRO(M) et Carte de conducteur CCEP accrédité par Voile
              Québec — Voile Canada.</p>
            <p>Instructeur reconnu comme étant un bon formateur, persévérant, organisé, imaginatif, méthodique,
              minutieux
              et
              doté d’une grande capacité d’adaptation, orienté sur le désir de transmettre sa passion de la voile et de
              la
              navigation de plaisance à ceux qui le côtoient.</p>

          </mat-card-content>
          <mat-card-actions fxLayout="row" fxLayoutAlign="end none">
            <button mat-stroked-button color="primary" routerLink="instructeur">En savoir
              plus...</button>
          </mat-card-actions>
        </div>
      </mat-card>
      <!-- END Instructeur -->

      <!-- Contactez-Nous -->
      <mat-card class="nav-card" class="mat-elevation-z8">
        <mat-card-header>
          <mat-card-title fxLayoutAlign="center center">
            <h2>Contactez-nous</h2>
          </mat-card-title>
        </mat-card-header>

        <div class="card_content">
          <mat-card-content>
            <app-contact-module></app-contact-module>
          </mat-card-content>
        </div>

      </mat-card>
    </div>

    <div class="mat-card-section">
      <mat-card class="nav-card" class="mat-elevation-z8">
        <mat-card-header>
          <mat-card-title fxLayoutAlign="center center">
            <h2>Formations et conférences</h2>
          </mat-card-title>
          <mat-card-subtitle>Des formations et conférences professionnelles.</mat-card-subtitle>
        </mat-card-header>


        <div class="card_content">
          <div class="image-container">
            <img class="card-image" src="../../../assets/porthole_winch.jpg" alt="Formation et conférences">
          </div>


          <mat-card-content>
            <p>L’utilisation de documents pédagogiques multimédias permet de disposer des environnements
              d’apprentissage
              orientés vers la compréhension rapide des éléments enseignés et présentés à sa clientèle.</p>
            <p>Elle se sent donc interpelée directement par les sujets présentés et s’implique de façon active lors des
              formations et conférences données.</p>

            <mat-chip-list>
              <mat-chip><a href="#" routerLink="formations">Initiation en voile croisière</a></mat-chip>
              <mat-chip><a href="#" routerLink="formations">Formation en radio maritime</a></mat-chip>
              <mat-chip><a href="#" routerLink="formations">Conférence « Tempêtes à l’horizon »</a></mat-chip>
            </mat-chip-list>

          </mat-card-content>
          <mat-card-actions fxLayout="row" fxLayoutAlign="end none">
            <button mat-stroked-button color="primary" routerLink="formations">En savoir
              plus...</button>
          </mat-card-actions>
        </div>
      </mat-card>


    </div>


  </div>
</div>