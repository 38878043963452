import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs/Rx';


const apiKey = 'AIzaSyBO-tmscKRSc8XUguGNCzTw7-viFt2G0-I';
const documentId = '1xtcuLlshZyI20xUo10CnC2lRj6SuJMZmpyiHilIR8c8'

@Injectable({
  providedIn: 'root'
})
export class GoogleService {

  constructor(private http: HttpClient) { }

  getDocument(sheetName): Observable<any> {
    return this.http.get<any[]>(
      "https://sheets.googleapis.com/v4/spreadsheets/" + documentId + "/values/" + sheetName + "?majorDimension=ROWS&key=" + apiKey,
      { responseType: "json" }
    );
  }
}
