import { Component, OnInit } from '@angular/core';
import { saveAs } from 'file-saver';
import { HttpClient } from "@angular/common/http";

@Component({
  selector: 'app-instructeur',
  templateUrl: './instructeur.component.html',
  styleUrls: ['./instructeur.component.css']
})
export class InstructeurComponent implements OnInit {

  constructor(private httpClient: HttpClient) { }

  ngOnInit() {
  }

  downloadCv() {
    this.httpClient.get('../../assets/cv.pdf', { responseType: 'blob' }).subscribe(res => {
      const myBlob: Blob = new Blob([res], { type: "octet/stream" });
      saveAs(myBlob, 'Cv_maritime_cled.pdf');
    });
  }

}
