import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-porthole-animation',
  templateUrl: './porthole-animation.component.html',
  styleUrls: ['./porthole-animation.component.css']
})
export class PortholeAnimationComponent implements OnInit {
  animation = [false];

  constructor() { }

  ngOnInit() {
    this.animation[0] = true;

  }

  ngAfterViewInit() {

    var i = 1;
    setInterval(() => {
      this.animation= [false];
      this.animation[i] = true;

      i == 2 ? i = 0 : i++;
    }, 3000)


  }

}
