<div class="container">
  <div class="content" fxLayoutAlign="center center" fxFlex fxLayoutGap="25px">
    <mat-card class="contact_card mat-elevation-z8">
      <mat-card-header class="contact_header">
        <div mat-card-avatar class="header_image"></div>
        <mat-card-title>
          <h2>Christian Leduc</h2>
        </mat-card-title>
      </mat-card-header>
      <div class="card_content">


        <mat-card-content fxLayout="row" fxLayout.lt-md="column">
          <div fxFlex="33" class="contact_info">
            <mat-list>
              <mat-list-item>
                <mat-icon matListIcon color="primary">contact_phone</mat-icon>
                <h4 matLine> 514-984-5845</h4>
              </mat-list-item>
              <mat-list-item>
                <mat-icon matListIcon color="primary">contact_mail</mat-icon>
                <h4 matLine>plaisance@c-led.ca</h4>
              </mat-list-item>
            </mat-list>



          </div>
          <app-contact-module fxFlex></app-contact-module>
        </mat-card-content>
      </div>
    </mat-card>
  </div>
</div>